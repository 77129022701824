<template>
  <div>
    <div class="mr-20" v-if="isShowSupplier">
      <span class="text-14">供货商: </span>
      <el-select v-model="supplier" filterable value-key="value" @change="setSupplier">
        <el-option
            v-for="item in supplierList"
            :key="item.value"
            :label="item.label"
            :value="item"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
import actions from "@/micro/actions";

export default {
  name: "supplier",
  props: {
    supplierType: {
      type: String,
      default() {
        return 'Supplier'
      }
    },
    supplierPaths: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  watch:{
    '$store.state[this._toLowerCase(this.supplierType)]':{
      handler(newVal, oldVal) {
        // console.log(newVal);
        this.supplierList = newVal;
      },
      deep: true, // 加这个属性，深度监听
    }
  },
  computed:{
    isShowSupplier() {
      return this.supplierPaths[this.supplierType]?.some( path => {
        Object.keys(this.$route.params).forEach(key => {
          path = path.replace(new RegExp("(:" + key + ")", "g"), this.$route.params[key]);
        })
        if(path === decodeURI(this.$route.path)) {
          return true
        }
      })
    },
    supplier: {
      get(){
        return this.$store.state[this._toLowerCase(this.supplierType)]
      },
      set(v) {
        //
      }
    },
    supplierList: {
      get(){
        return this.$store.state[this._toLowerCase(this.supplierType) + 'List']
      },
      set(v) {
        //
      }
    }
  },
  methods: {
    _toLowerCase(a) {
      return a[0].toLowerCase() + a.substr(1)
    },
    setSupplier(data) {
      this.$store.commit({
        type: 'set'+ this.supplierType,
        data
      })
      actions.setGlobalState({
        type: 'set'+ this.supplierType,
        payload: {data},
      });
    },
  }

}
</script>

<style scoped>

</style>