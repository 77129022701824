<template>
  <div id="main-app">
    <router-view />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  created() {
    this.fetchPartnerList();
  },
  methods: {
    ...mapActions(['fetchPartnerList']),
    async getList() {
      await this.fetchPartnerList();
    },
  },
};
</script>
<style lang="less">
@import './style/base.less';
html,
body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}
#main-app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: inherit;
}
#main-app .layout-container {
  height: auto;
}

.el-menu a {
  color: #fff;
  text-decoration: none;
}

#main-app,
.el-menu--vertical {
  .el-menu-item.is-active {
    background-color: #ecf5ff !important;
  }
  .el-menu-item:hover {
    background-color: #ecf5ff !important;
  }
  .el-submenu__title:hover {
    background-color: #ecf5ff !important;
  }
}
</style>
